body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
}

h1 {
    font-size: 3rem;
    background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet);
    background-size: 400%;
    animation: rainbow 10s infinite linear;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

@keyframes rainbow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}